<template>
  <div >
      我是文档
  </div>
</template>

<script>

export default {
  name: 'home',
  mounted() {
  },
  methods: {
   
  }
}
</script>

<style lang="scss" scoped>

</style>
